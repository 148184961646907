<template>
    <section id="ft-featured" class="ft-featured-section">
		<div class="container">
			<div class="ft-section-title-2 headline pera-content text-center">
				<span class="sub-title">India presence</span>
				<h2>Our India
					<span>Branches</span>
				</h2>
			</div>
			<div class="ft-featured-content">
				<div class="row justify-content-center">  
					<div class="col-lg-4 col-md-4 wow fadeInUp mr-bottom" data-wow-delay="600ms" data-wow-duration="1500ms" v-for="item in mainData" :key="item.id">
						<div class="ft-featured-innerbox position-relative minHight3"> 
							<div class="ft-featured-text headline pera-content">
								<h3 class="nationBranchTitle"><a href="javascript:void(0)">{{ item.city }}</a></h3> 
                                <div class="addressWrapper">
                                    <div class="addressWrapperTitle">{{ item.compname }}</div>
                                    <div class="addressWrapperAddress">
                                        {{ item.address }}
                                    </div>
                                    <p v-if="item.tel != ''"><b>Tel: </b>{{ item.tel }}</p>
                                    <p v-if="item.mobile != ''"><b>Mobile: </b>{{ item.mobile }}</p>
                                    <p v-if="item.email != ''"><b>Email: </b>{{ item.email }}</p>
                                    <p v-if="item.weblink != ''"><b>Web: </b>{{ item.weblink }}</p>
                                </div>
							</div>
						</div>
					</div> 
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import data from '../../data/data.json'
export default {
    data(){
        return{
            mainData: ""
        }
    },
    created(){
        this.mainData = data.indiaNetwork
        // console.log("this is Data", this.mainData)
    },
}
</script>