<template>
    <breadCrumb/>
    <indiaBranch/>
</template>

<script>
import breadCrumb from '../components/topSection/topSection.vue'
import indiaBranch from '../components/presence/india.vue'
export default {
    components: {
        breadCrumb, 
        indiaBranch
    },
    data(){
        return{}
    }
}
</script>